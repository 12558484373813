import React from 'react'
import SEO from "../components/atoms/seo"

import Layout from '../components/organisms/layout'
import Heading from '../components/molecules/heading'
import Questionnaire from '../components/organisms/questionnaire'
import KeyFeatures from '../components/molecules/key-features'

import steps from "../data/pages/questionnaire/steps.json"
import verifyInformation from "../data/pages/questionnaire/verify.json"
import content from "../data/pages/questionnaire/content.json"

const QuestionnairePage = () => (
  <Layout type='floor' showBG showFormSecureBanner>
    <SEO title={content.seo.title} />
    <br />
    <br />
    <br />
    <Heading 
        title=''
        subTitle=''
        weight='regular'
    />
    <Questionnaire 
      steps={steps}
      verifyInformation={verifyInformation}
      disclaimer={content.disclaimer}
      onSubmitMessage={content.onSubmit.message}
      thankYouURL={content.onSubmit.thankYouURL}
      formType="garage" // this is how we control what google sheets and API URL to use
      thankYouChatBot
      // useTestSubmitLabel={content.onSubmit.testSubmitLabels}
    />
    <KeyFeatures
      features={[
        {
            title: 'Exclusive Formula',
            image: 'ufc-formula.png',
            imageAlt: ''
        },
        {
            title: 'Unbreakable',
            image: 'ufc-unbreakable.png',
            imageAlt: ''
        },
        {
            title: 'Done in 1 day',
            image: 'ufc-24hours.png',
            imageAlt: ''
        }
    ]}
    />
  </Layout>
)

export default QuestionnairePage
